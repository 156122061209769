import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

let videoModal = $('#videoModal')

videoModal.on('open.zf.reveal', function() {
    $('#XBRLPromoVideo').get(0).play();
});

videoModal.on('closed.zf.reveal', function() {
    $('#XBRLPromoVideo').get(0).pause();
});


$(window).on('load', function () {
	let cookie = getCookie("GDPRConsent");
	if(!cookie) {
		$("#gdpr-message").show();
	}
});

$("#gdpr-message a.button").click(function () {
	setCookie();

	return false;
});

function getCookie(name) {
    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')); return match ? match[1] : null;
}

function setCookie(){
	let d = new Date();
	let year = d.getFullYear();
	let month = d.getMonth();
	let day = d.getDate();
	let date = new Date(year + 2, month, day);
	let dateString = date.toGMTString();
    document.cookie = "GDPRConsent=true; expires=" + dateString;
    $("#gdpr-message").hide();
}

$("#contactForm").submit(function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    var $error = false;
    if(check_required("contactForm-contact_name", "") === true) { $error = true; }
    if(check_required("contactForm-contact_company", "") === true) { $error = true; }
    if(check_required("contactForm-contact_phone", "") === true) { $error = true; }
    if(check_required("contactForm-contact_e_mail", "email") === true) { $error = true; }
    if(check_required("contactForm-gdpr_consent", "checkbox") === true) { $error = true; }

    if($error === true) {
        return false;
    }

    var formData = $('#contactForm').serialize();

    $("#contactFormLoadingModal").foundation('open');

    $.post("send_mail.php", formData, function(data) {
        if(data.hasOwnProperty('success')) {
            setTimeout(function(){
                $("#contactFormLoadingModal").foundation('close');
                $("#contactFormSuccessModal").foundation('open');
            }, 3000);
        } else {
            setTimeout(function(){
                $("#contactFormLoadingModal").foundation('close');
                $("#contactFormFailModal").foundation('open');
            }, 3000);
        }
    }).fail(function(data) {
        setTimeout(function(){
            $("#contactFormLoadingModal").foundation('close');
            $("#contactFormFailModal").foundation('open');
        }, 3000);
    });

    return false;
});

function check_required(field_name, type, $showWarning=true) {
	var required_field_text = "<p class='form-error'>This field is required!</p>";
    var email_field_text = "<p class='form-error'>Please enter valid e-mail address!</p>";
    if(type === "select") {
        var field = $("select[name=" + field_name + "]");
    } else {
        var field = $("input[name=" + field_name + "]");
    }

    if(type === "checkbox") {
		var field_value = field.is(":checked");
	} else {
		var field_value = field.val();
	}

    if($showWarning === true) { field.parent().find(".form-error").remove(); field.parent().parent().find(".form-error").remove();  }

    if(type === "email") {
        if(!validateEmail(field_value)) {
            if($showWarning === true) {
              field.parent().append(email_field_text);
            }

            return true;
        }
    } else if(type === "checkbox" && field_value === false) {
		if($showWarning === true) {
		  field.parent().parent().append(required_field_text);
		}

		return true;
    } else if (field_value === "" || undefined) {
        if($showWarning === true) {
            field.parent().append(required_field_text);
        }

        return true;
    }

    return false;
}

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}